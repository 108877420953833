import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static values  = { paperCheckId: String, returnUrl: String, url: String }
  static targets = [
    "addressControl",
    "amount",
    "from",
    "to",
    "confirmAmount",
    "confirmFrom",
    "confirmTo",
  ]

  connect() {
    this.toggleAddress()
  }

  selectedText(elem) {
    return elem.options[elem.selectedIndex].text
  }

  summarize(event) {
    let n                              = parseFloat(this.amountTarget.value)
    let amount                         = Math.round((n + Number.EPSILON) * 100) / 100
    this.confirmFromTarget.innerHTML   = this.selectedText(this.fromTarget)
    this.confirmToTarget.innerHTML     = this.selectedText(this.toTarget)
    this.confirmAmountTarget.innerHTML = `$${amount}`
  }

  toggleAddress() {
    let value = this.toTarget.options[this.toTarget.selectedIndex].value
    if (value.toString() == this.paperCheckIdValue) {
      this.addressControlTarget.classList.remove("hidden")
    } else {
      this.addressControlTarget.classList.add("hidden")
    }
  }
}
