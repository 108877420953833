import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController

  @targets = [
    "conditions",
    "departmentTemplate",
    "hrisTemplate",
    "individualTemplate",
    "titleTemplate",
    "startDateTemplate",
    "startDateOffsetTemplate",
  ]

  connect: ->
    if $(@conditionsTarget).find(".condition").length
      $(@conditionsTarget).show()
      $(@conditionsTarget).find(".condition .fields").filter((i, f)-> $(f).find(".error").length).each ->
        $(@).show()

    $(document).on "click", ".condition .field-trigger", ->
      fields = $(@).parents(".condition").find(".fields")
      $(".condition .fields").not(fields).slideUp()
      fields.slideToggle()

    $(document).on "click", ".condition .done", (e) ->
      e.preventDefault()
      $(@).parents(".fields").slideUp()

    $(document).on "change", ".condition .variable-value-input", (e) ->
      text = if $(@).is("select")
        $(@).find("option:selected").text()
      else if $(@).parents(".pillbox").length
        $.map($(".selectize-input .item"), (i) -> $(i).text()).join(", ")
      else
        $(@).val()
      $(@).parents(".condition").find(".variable-value-text").html(text)

    $(document).on "change", ".condition .logical-operator-input", (e) ->
      text = $(@).find("option:selected").text()
      $(@).parents(".condition").find(".logical-operator-text").html(text)

    $(document).on "click", ".condition .connecting-operator-text", (e) ->
      $(".connecting-operator-form").hide()
      $(@).parents(".condition").find(".connecting-operator-form").toggle()

    $(document).on "change", ".condition .connecting-operator-input", (e) ->
      text = $(@).attr("label")
      $(".connecting-operator-input[value='#{$(@).val()}']").each ->
        $(@).prop("checked", "checked")
      $(".connecting-operator-text").html(text)
      $(@).parents(".condition").find(".connecting-operator-form").hide()

  disconnect: ->
    $(document).off("click", ".condition .field-trigger")
    $(document).off("click", ".condition .done")
    $(document).off("change", ".condition .vaiable-value-input")
    $(document).off("change", ".condition .logical-operator-input")

  addCondition: (e) ->
    e.preventDefault()

    $(@conditionsTarget).show()
    $(".condition .fields").slideUp()
    html = @["#{e.params["template"]}TemplateTarget"]
    content = $(html.innerHTML.replace(/NEW_CONDITION/g, new Date().getTime()))
    connVal = $(@conditionsTarget).find(".connecting-operator-form :checked").val()
    connTxt = $(@conditionsTarget).find(".connecting-operator-text").first().text()
    content.find(".radio").each ->
      id    = (Math.random() + 1).toString(36).substring(2)
      radio = $(@).find("#RADIO_ID")
      radio.prop("checked", "checked") if radio.val() == connVal
      radio.attr("id", id)
      $(@).find("[for='RADIO_ID']").attr("for", id)
    $(@conditionsTarget).append(content)
    content.find(".connecting-operator-text").html(connTxt) if connTxt.length
    content.find(".fields").slideDown()

  removeCondition: (e) ->
    e.preventDefault()

    condition = $(e.target.closest(".condition"))
    condition.find("._destroy").val("1")
    unless condition.find(".id").val().length
      condition.remove()
    else
      condition.hide()
    if $(@conditionsTarget).find(".condition:visible").length == 0
      $(@conditionsTarget).hide()

$(document).on "click", (e) ->
  control = $(e.target).parents(".connecting-operator-form")
  unless control.has(e.target).length || $(e.target).hasClass("connecting-operator-text")
    $(".connecting-operator-form").hide()
