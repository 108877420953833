import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController

  @targets = ['input']

  connect: ->
    form  = @element
    $form = $(form)
    $(@inputTargets).each ->
      $(@).change ->
        $.ajax
          url:      $form.attr('action')
          data:     $form.serialize()
          dataType: "json"
          method:   $form.attr('method')
          success: (data) =>
            $form.trigger('ajax-form:success', data)
